<script setup lang="ts">
const minOtcLtd = computed(() => {
  return (
    states.find((state) => state.code === ip.lookup?.stateCode)?.minOtcLtd ??
    500
  )
})

const isProd = import.meta.env.MODE === 'production'

const show = ref(false)
const isMobile = ref(true)
const isPaidUser = ref(true)
const router = useRouter()
const route = useRoute()

onMounted(() => {
  isMobile.value = window.innerWidth < 640
  isPaidUser.value = getSessionEnhanced().isPaidUser

  if (
    query.utm_source?.toLowerCase() === 'taboola' ||
    query.utm_source?.toLowerCase() === 'newsbreak'
  ) {
    enterExperiment('groceryAllowanceR2Redirect')
  }

  if (exp.value?.groceryAllowanceR2Redirect?.redirect) {
    router.replace({
      path: '/grocery-allowance-2',
      query: route.query,
    })
    return
  }

  show.value = true
})
</script>

<template>
  <Layout v-if="show" header="2025" footer="2025">
    <template #banner>
      <Banner> Have Medicare and Medicaid? </Banner>
    </template>
    <div class="container max-w-2xl py-8 space-y-6 text-lg">
      <template v-if="isMobile && isPaidUser">
        <h1 class="text-2xl font-bold text-center">
          Find Ways to Help Reduce Grocery Bills
        </h1>
        <div class="text-gray-600">
          See If You're Eligible For a ${{ minOtcLtd }}/yr Medicare Advantage
          Grocery Allowance
        </div>
      </template>
      <template v-else>
        <h1 class="text-2xl font-bold text-center">
          Find Ways to Help Reduce High Grocery Bills: See If You Are Eligible
          For a Medicare Advantage D-SNP plan with
          <span v-if="isMobile">${{ minOtcLtd }} per year or more in</span>
          <span v-else>an annual</span>
          Allowance to Use Towards Groceries from Well-Known Carriers like
          Humana and UnitedHealthcare®
        </h1>
      </template>

      <img src="../../assets/advertorial-hero.jpg" />

      <div>
        Are you feeling the pinch with everyday expenses like
        <b> groceries and other expenses? </b>
      </div>

      <div>
        You're not alone! Millions of Americans are seeing rising food prices.
        <b>
          But there could be some help available for Medicare beneficiaries that
          may be eligible for select Medicare Advantage plans with a Medicare
          food allowance benefit.
        </b>
      </div>

      <img
        src="../../assets/advertorial-food-prices-inflation.webp"
        class="py-4"
      />

      <div>
        <b>
          Imagine finding a Medicare Advantage Plan with benefits like
          <span v-if="isMobile">${{ minOtcLtd }} per year or more in</span>
          <span v-else>an annual</span>
          allowance
        </b>
        to help pay for your grocery shopping.
      </div>

      <div>
        No need to imagine much longer because some
        <b> Medicare Advantage D-SNP plans already offer this benefit. </b>
      </div>

      <div>
        If you're on Medicare and Medicaid, and you're not using a Medicare
        Advantage plan with an Allowance towards Healthy Food.
      </div>

      <div>
        <b>
          And it's so easy to check which Medicare Advantage plans with this
          benefit are available in your area.
        </b>
      </div>

      <div class="bg-yellow-100 border border-yellow-500 p-4 rounded-md">
        Dial
        <ClickToCall v-slot="{ handleClick }">
          <Link
            class="!text-blue-700 font-medium"
            :to="phone.uri"
            @click="handleClick"
          >
            {{ phone.formatted }}
          </Link>
        </ClickToCall>
        TTY 711, Monday-Friday 8am-7pm for a free, no obligation chat with a
        licensed insurance agent. There's zero obligation to sign up, and the
        average wait time is
        <b>just 2 to 3 minutes.</b>
      </div>

      <div>
        They'll show you different Medicare Advantage Plans and see if you are
        eligible to enroll in a D-SNP plan with an Allowance towards Healthy
        Food.
      </div>

      <div>
        Don't let rising food costs eat into your budget.
        <b>
          Call now and see if you are eligible for a Medicare Advantage Plan
          with <span v-if="isMobile">${{ minOtcLtd }} per year or more in</span>
          <span v-else>an annual</span>
          Allowance to use towards groceries with some Medicare Advantage Plans.
        </b>
      </div>

      <div
        class="-mx-4 [box-shadow:0_0_6px_rgba(0,0,0,0.3)] sm:rounded sm:mx-0 bg-white"
      >
        <Form
          id="groceryAllowanceR2"
          class="!max-w-3xl p-4"
          scrollBehavior="keepPos"
          :steps="[
            { id: 'medicareab' },
            { id: 'us' },
            { id: 'wantDsnp' },
            { id: 'loading' },
            { id: 'call', if: isProd ? isMobile && isWorkingHours : true },
            { id: 'clicks' },
          ]"
          product="medicare"
        />
      </div>
    </div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "brands": ["bh", "m10"],
    "robots": "noindex",
    "smid": {
      "bh": "MULTI-PLAN_HLWEB0924256_M",
      "m10": "MULTI-PLAN_HLWEB0624208_M"
    }
  }
}
</route>
